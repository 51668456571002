<template>
    <v-dialog v-model="dialogDelete" width="700px" :retain-focus="false">
        <v-card>
            <v-card-title class="text-h5">{{ $t(thisTitle) }}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click.stop="deleteCancel">{{ $t('main.cancel') }}</v-btn>
                <v-btn color="success" @click.stop="deleteItem" >{{ $t('main.ok') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteDialog",
    emits: ['confirm-dialog'],
    props: {
        toggleDialog: {
            type: Boolean,
            required: true
        },
        itemType: {
            type: String,
            required: true
        },
    },
    data(){
        return {
            dialogDelete: false,
            formTitle: ''
        };
    },
    computed: {
        thisTitle(){
            return this.itemType === "schedule" ? "errors.sureDeleteSchedule" : "errors.sureDeleteZone";
        }
    },
    mounted() {
        this.dialogDelete = this.toggleDialog;
    },
    methods: {
        deleteItem() {
            this.$emit('confirm-dialog', true);
            this.formTitle = '';
            this.dialogDelete = false;
        },
        deleteCancel(){
            this.$emit('confirm-dialog', false);
            this.formTitle = '';
            this.dialogDelete = false;
        }
    }
}
</script>